<template>
<b-container>
  <b-row no-gutters class="main" align-v="center">
    <div class="d-none d-md-flex col-md-4 col-lg-6" style="width:100%;height:35%;">
        <div class="bg-image" style="width:100%;height:100%"></div>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="login d-flex align-items-center py-5">
            <b-container>
                <b-row align-h="center">
                    <b-col cols="7" md="5">
                        <b-img src="../assets/img/Twin Cutout-Black.png" fluid></b-img>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="9" lg="8" class="mx-auto mt-3">
                        <h3 class="mb-2 text-center">Welcome back!</h3>
                        <b-form>
                            <b-form-group label="Email address:" label-for="email">
                                <b-form-input id="email" v-model="email" type="email" placeholder="Enter email"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Password:" label-for="password">
                                <b-form-input id="password" v-model="password" type="password" placeholder="Password"></b-form-input>
                            </b-form-group>
                            

                            <button class="btn btn-lg btn-dark btn-block btn-login mb-2" @click.prevent="loginUser()">Log In</button>
                            <b-alert v-model="error" class="text-center" :variant="alertVariant">
                                <p class="small text-error">{{errorMsg}}</p>
                            </b-alert>
                        </b-form>
                    </b-col>
                </b-row>
            </b-container>
      </div>
    </div>
  </b-row>

  <modal v-show="showModal" @close="closeModal">
    <template v-slot:header>
        You must choose a new password!
    </template>
    <template v-slot:body>
        <span class="helpTxt">Your password has expired. Please choose a new password:</span>
        
        <b-form-text class="mt-3">New Password:</b-form-text>
        <b-form-input id="password1" v-model="resetPassword.password1" type="text" required></b-form-input>
        <b-form-text id="password-help-block">Your password must be atleast 8 characters long. It can be any combination of leters or numbers. Password are case sensitive. It can not contain spaces, any special characters (e.g. #$@!), or emoji.</b-form-text>
        <b-form-text class="mt-3">Verify Password:</b-form-text>
        <b-form-input id="password2" v-model="resetPassword.password2" type="text" required></b-form-input>
        <div id="alert" v-show="resetPassword.alert">{{ resetPassword.alert }}</div>

    </template>
    <template v-slot:footer>
        <b-button variant="primary" @click="checkAndResetPassword">Reset Password</b-button>
    </template>
  </modal>

</b-container>
</template>

<script>
import {initSession} from '@/config/session-manager.js'
import modal from '@/components/Modal.vue'

export default {
    name: 'LoginFrm',
    components: {
        modal
    },
    data() {
        return {
            email: '',
            password: '',
            error: false,
            errorMsg: "Whoops, check the console...",
            resetPassword: { 
                userId: 0,
                password1: "",
                password2: "",
                alert: ""
            },
            alertVariant: 'danger',
            showModal: false
        }
    },
    methods: {
        async loginUser() {
            this.error = false
            try {
                let loginData = {
                    email: this.email,
                    password: this.password
                }
                //console.log(`Logging in User with ${this.email} and ${this.password}`)
                
                let response = await this.$axios.post("/auth/login", loginData)
                
                console.log(response.data)
                
                if(response.data.resetPassword){
                    this.alertVariant = 'warning'
                    this.error = true
                    this.errorMsg = "Password must be reset! Please choose a new password."
                    this.resetPassword.userId = response.data.userId
                    this.showModal = true
                } else {
                    let token = response.data.token
                                
                    localStorage.setItem("jwt", token)
                    if (token) {
                        console.log("Success: Login Successful")
                        initSession()
                        this.$router.push("/")
                    }
                }
                
            } catch (err) {
                this.error = true
                this.errorMsg = err
                console.log(err)
            }   
        },
        async checkAndResetPassword() {
            this.resetPassword.alert = ""
            // Verify that the passwords match
            if (this.resetPassword.password1 !== this.resetPassword.password2) {
                this.resetPassword.alert = "Passwords must match!"
                return
            }

            //passwords match but does it match our pattern
            const pattern = /^[a-zA-Z0-9]{8,}$/
            // ^ - The beginning of the string
            // [a-zA-Z0-9] - Matches any letter (uppercase or lowercase) or number
            // {8,} - Matches 8 or more of the preceding pattern (in this case, any letter or number)
            // $ - The end of the string
            if(!pattern.test(this.resetPassword.password1)) {
                //password does not meet criteria.
                this.resetPassword.alert = "Passwords must be at least 8 characters long and be any mix of letters and numbers. No symbols or emoji. "
                return
            }
            

            console.log("Password match and meet all criteria. Call backend to update users password.")
            this.showModal = false
            this.resetPassword.alert = ""
            // Call backend to reset password
            const payload = {
                userId : this.resetPassword.userId,
                oldPassword: this.password,
                email: this.email,
                password: this.resetPassword.password1
            }

            try {
                let res = this.$axios.put("/auth/updatePassword", payload)
                console.log(res)

            } catch (err) {
                console.log("error with resetPassword request:")
                console.log(err)
            }
           
        },
        closeModal(){
            this.showModal = false
        }

    },
    created () {
        this.$state.showNav = false
    }
}
</script>

<style lang="scss" scoped>
    .login-heading {
        font-weight: 300;
    }
    .bg-image {
        background-image: url(https://loremflickr.com/600/480/roofing);
        background-position: center; 
        background-size: cover;

    }
    .main {
        height: 100vh;
    }
    #alert {
        color: red;
        margin-top: 10px;
        margin-bottom: 10px;
    }

</style>