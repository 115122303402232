
<template>
    <div class="kmodal-backdrop">
      <div class="kmodal">
        <header class="modal-header">
            <slot name="header">
                This is the Modal's Title
            </slot>
            <button tyoe="button" class="btn-close" @click="close">X</button>
        </header>
        
        <section class="modal-body">
            <slot name="body">
                This is the default modal body
            </slot>
        </section>
        
        <footer class="modal-footer">
            <slot name="footer">
                
            </slot>
            <button type="button" class="btn btn-dark" @click="close">Close</button>
        </footer>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style>
  .kmodal-backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .kmodal {
    z-index: 2;
    background-color: #FeFeFe;
    margin: 15% auto;
    max-width: 70%;
    box-shadow: 2px 2px 20px 1px;
    border-radius: 10px;
  }

  @media (max-width: 480px) {
    .kmodal {
        max-width: 94%;
    }
    
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    background-color: #017BFE;
    color: #FFF;
    font-family: 'Roboto Mono'!important;
    font-weight: bold;
    font-size: 1.2em;
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    .modal-header {
        font-size: 1.1em;
    }
    
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 15px;
  }

  .btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    font-size: 20px;
    padding: 0px 8px;
    cursor: pointer;
    font-weight: bold;
    color: #FFF;
    background: transparent;
    border: 1px solid white;
    border-radius: 3px;
    margin: 5px;
  }
  @media (max-width: 480px) {
    .btn-close {
        display: none;
    }
    
  }

  .btn-cancel {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
</style>